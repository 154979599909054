import React, { useState } from "react";
import { TouchableOpacity, Text, View } from "@/ui/index";
import { Colors } from "@/constants";
import Icon from "@/ui/Icon";
import MobileContainer from "@/ui/MobileContainer";
import DesktopContainer from "@/ui/DesktopContainer";

const FaqItem = ({ title, text }: { title: string; text: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <View
      style={{
        backgroundColor: open ? Colors.landingFaq : Colors.white,
        paddingBottom: open ? 24 : 0,
        marginBottom: 8,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: `${Colors.accent}25`,
        paddingHorizontal: 24,
      }}
    >
      <TouchableOpacity
        onPress={() => setOpen(!open)}
        activeOpacity={0.5}
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderColor: `${Colors.accent}25`,
          minHeight: 72,
          paddingVertical: 16,
        }}
      >
        <MobileContainer>
          <Text
            style={{
              color: Colors.text,
              fontSize: 16,
              lineHeight: 20,
              marginRight: 10,
              fontWeight: open ? "600" : "400",
            }}
          >
            {title}
          </Text>
        </MobileContainer>

        <DesktopContainer>
          <Text
            style={{
              color: Colors.text,
              fontSize: 18,
              fontWeight: open ? "600" : "400",
            }}
          >
            {title}
          </Text>
        </DesktopContainer>

        <Icon
          icon={open ? "caretUp" : "caretDown"}
          style={{ tintColor: Colors.subtitle, width: 16, height: 16 }}
        />
      </TouchableOpacity>

      {Boolean(open) && (
        <Text
          style={{
            marginTop: 6,
            fontSize: 18,
            lineHeight: 28,
            color: Colors.text,
          }}
        >
          {text}
        </Text>
      )}
    </View>
  );
};

export default FaqItem;
