import React from "react";
import { View, Text, StyleSheet, Pressable } from "@/ui/index";
import { Colors } from "@/constants";
import Icon from "@/ui/Icon";
import DesktopContainer from "@/ui/DesktopContainer";
import MobileContainer from "@/ui/MobileContainer";
import { useTranslation } from "react-i18next";
import { getLangDir } from "@/models/utils";

const PriceItem = ({
  title,
  footerText1,
  footerText2,
  subtitle,
  price,
  priceSubtitle,
  isPrimary = false,
  onPress,
}: {
  onPress: () => void;
  price: string;
  title: string;
  subtitle: string;
  priceSubtitle: string;
  footerText1: string;
  footerText2: string;
  isPrimary?: boolean;
}) => {
  const { t } = useTranslation();
  const buttonText = t("landingPricing.tryItFree");
  const isRTL = getLangDir() === "rtl";

  return (
    <>
      <MobileContainer>
        <View
          style={{
            ...s.container,
            maxWidth: "auto",
            marginHorizontal: 16,
            ...(isRTL && {
              alignItems: "flex-start",
            }),
          }}
        >
          <Text style={s.title}>{title}</Text>
          <Text style={s.subtitle}>{subtitle}</Text>

          <Text style={s.price}>
            ${price}
            <Text style={s.currency}>USD</Text>
          </Text>
          <Text style={s.priceSubtitle}>{priceSubtitle}</Text>

          <Pressable onPress={onPress}>
            <View
              style={{
                ...s.button,
                backgroundColor: isPrimary
                  ? Colors.landingAccent
                  : Colors.white,
              }}
            >
              <Text
                style={{
                  color: isPrimary ? Colors.white : Colors.accent,
                  fontWeight: "500",
                  fontSize: 18,
                }}
              >
                {buttonText}
              </Text>
            </View>
          </Pressable>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Icon
              icon="check"
              style={{ tintColor: Colors.text, width: 22, height: 22 }}
            />
            <Text
              style={{
                marginLeft: 8,
                color: Colors.text,
                fontSize: 16,
                ...(isRTL && { marginLeft: 0, marginRight: 8 }),
              }}
            >
              {footerText1}
            </Text>
          </View>

          <View
            style={{
              marginTop: 24,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon
              icon="check"
              style={{ tintColor: Colors.text, width: 22, height: 22 }}
            />
            <Text
              style={{
                marginLeft: 8,
                color: Colors.text,
                fontSize: 16,
                ...(isRTL && { marginLeft: 0, marginRight: 8 }),
              }}
            >
              {footerText2}
            </Text>
          </View>
        </View>
      </MobileContainer>

      <DesktopContainer>
        <View
          style={{
            ...s.container,
            ...(isRTL && {
              alignItems: "flex-start",
            }),
          }}
        >
          <Text style={s.title}>{title}</Text>
          <Text style={s.subtitle}>{subtitle}</Text>

          <Text style={s.price}>
            ${price}
            <Text style={s.currency}>USD</Text>
          </Text>
          <Text style={s.priceSubtitle}>{priceSubtitle}</Text>

          <Pressable onPress={onPress}>
            <View
              style={{
                ...s.button,
                backgroundColor: isPrimary
                  ? Colors.landingAccent
                  : Colors.white,
              }}
            >
              <Text
                style={{
                  color: isPrimary ? Colors.white : Colors.accent,
                  fontWeight: "500",
                  fontSize: 18,
                }}
              >
                {buttonText}
              </Text>
            </View>
          </Pressable>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Icon
              icon="check"
              style={{ tintColor: Colors.text, width: 22, height: 22 }}
            />
            <Text
              style={{
                marginLeft: 8,
                color: Colors.text,
                fontSize: 16,
                ...(isRTL && { marginLeft: 0, marginRight: 8 }),
              }}
            >
              {footerText1}
            </Text>
          </View>

          <View
            style={{
              marginTop: 24,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon
              icon="check"
              style={{ tintColor: Colors.text, width: 22, height: 22 }}
            />
            <Text
              style={{
                marginLeft: 8,
                color: Colors.text,
                fontSize: 16,
                ...(isRTL && { marginLeft: 0, marginRight: 8 }),
              }}
            >
              {footerText2}
            </Text>
          </View>
        </View>
      </DesktopContainer>
    </>
  );
};

const s = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    maxWidth: 320,
    padding: 40,
    borderRadius: 8,
  },
  title: {
    fontWeight: "600",
    fontSize: 34,
    marginBottom: 20,
    color: Colors.title,
  },
  subtitle: { fontSize: 18, lineHeight: 24, color: Colors.text },
  price: { marginTop: 30, fontSize: 48, fontWeight: "600" },
  currency: { fontSize: 25, marginLeft: 2 },
  priceSubtitle: { color: Colors.subtitle, fontSize: 18, marginTop: 10 },
  button: {
    borderWidth: 2,
    borderColor: Colors.accent,
    height: 48,
    marginTop: 48,
    marginBottom: 24,
    paddingHorizontal: 30,
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default PriceItem;
