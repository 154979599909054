import { View, Text, StyleSheet } from "@/ui";
import { Colors } from "../constants";

const ReviewItem = ({
  review,
  author,
  flag,
}: {
  review: string;
  author: string;
  flag: string;
}) => {
  return (
    <View style={s.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "flex-start",
          marginBottom: 4,
        }}
      >
        <Text style={{ fontSize: 24, marginRight: 4 }}>{flag}</Text>
        <Text style={s.author}>{author}</Text>
      </View>

      <Text style={{ ...s.reviewText, maxWidth: "auto" }}>{review}</Text>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    padding: 24,
    borderWidth: 1,
    borderColor: `${Colors.accent}20`,
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 300,
    width: "100%",
    flexGrow: 1,
  },
  reviewText: {
    fontSize: 18,
    lineHeight: 26,
    color: Colors.text,
  },
  author: {
    fontSize: 18,
    fontWeight: "600",
    color: Colors.title,
    marginBottom: 4,
  },
});

export default ReviewItem;
