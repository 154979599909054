import { Text } from "@/ui";
import { Colors } from "@/constants";
import { useUISlice } from "@/models/useUISlice";

const LandingPageTitle = ({ title }: { title: string }) => {
  const isMobile = useUISlice((state) => state.isMobile);

  return (
    <Text
      style={{
        fontWeight: "700",
        color: Colors.title,
        textAlign: "center",
        marginBottom: isMobile ? 25 : 50,
        fontSize: isMobile ? 30 : 48,
        lineHeight: isMobile ? 36 : 48,
      }}
    >
      {title}
    </Text>
  );
};

export default LandingPageTitle;
