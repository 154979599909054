import { StyleSheet, View, Text } from "@/ui/index";
import FaqItem from "@/ui/FaqItem";
import { Colors, DESKTOP_WIDTH } from "@/constants";
import { useUISlice } from "@/models/useUISlice";
import LandingPageTitle from "./LandingPageTitle";
import { useTranslation } from "react-i18next";

const LandingPageFaq = () => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { t } = useTranslation();

  return (
    <View
      style={{
        paddingVertical: isMobile ? 50 : 100,
        backgroundColor: Colors.landingGray,
      }}
    >
      <View style={{ ...s.wrapper, paddingHorizontal: isMobile ? 16 : 0 }}>
        <LandingPageTitle title={t("landingPageFaq.title")} />

        <FaqItem
          title={t("landingPageFaq.question1")}
          text={t("landingPageFaq.answer1")}
        />

        <FaqItem
          title={t("landingPageFaq.question2")}
          text={t("landingPageFaq.answer2")}
        />

        <FaqItem
          title={t("landingPageFaq.question3")}
          text={t("landingPageFaq.answer3")}
        />

        <FaqItem
          title={t("landingPageFaq.question4")}
          text={t("landingPageFaq.answer4")}
        />

        <FaqItem
          title={t("landingPageFaq.question5")}
          text={t("landingPageFaq.answer5")}
        />

        <FaqItem
          title={t("landingPageFaq.question6")}
          text={t("landingPageFaq.answer6")}
        />

        <FaqItem
          title={t("landingPageFaq.question7")}
          text={t("landingPageFaq.answer7")}
        />

        <FaqItem
          title={t("landingPageFaq.question8")}
          text={t("landingPageFaq.answer8")}
        />

        <FaqItem
          title={t("landingPageFaq.question9")}
          text={t("landingPageFaq.answer9")}
        />

        <FaqItem
          title={t("landingPageFaq.question10")}
          text={t("landingPageFaq.answer10")}
        />

        <FaqItem
          title={t("landingPageFaq.question11")}
          text={t("landingPageFaq.answer11")}
        />
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    backgroundColor: Colors.landingFaq,
    paddingHorizontal: 16,
  },
  title: {
    color: Colors.title,
    marginBottom: 50,
    alignSelf: "center",
    fontWeight: "700",
    fontSize: 48,
    lineHeight: 56,
    textAlign: "center",
  },
  wrapper: {
    width: "100%",
    alignSelf: "center",
    maxWidth: DESKTOP_WIDTH * 0.7,
  },
});

export default LandingPageFaq;
