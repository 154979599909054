import { View, Text, StyleSheet, Pressable } from "@/ui";
import { Colors, DESKTOP_WIDTH } from "@/constants";
import Icon, { icons } from "./Icon";
import { useUISlice } from "@/models/useUISlice";
import LandingPageTitle from "./LandingPageTitle";
import { useTranslation } from "react-i18next";
import { i18n } from "@/locales";
import { useAuth } from "@/models/auth";

const features: {
  icon: keyof typeof icons;
  title: string;
  subtitle: string;
}[] = [
  {
    icon: "emojiInfinite",
    title: i18n.t("landingFeatures.noLimits"),
    subtitle: i18n.t("landingFeatures.noLimitsSubtitle"),
  },
  {
    icon: "emojiSpeaker",
    title: i18n.t("landingFeatures.speakerRecognition"),
    subtitle: i18n.t("landingFeatures.speakerRecognitionSubtitle"),
  },
  {
    icon: "emojiTarget",
    title: i18n.t("landingFeatures.accuracy"),
    subtitle: i18n.t("landingFeatures.accuracySubtitle"),
  },
  {
    icon: "emojiEarth",
    title: i18n.t("landingFeatures.languages"),
    subtitle: i18n.t("landingFeatures.languagesSubtitle"),
  },
  {
    icon: "emojiExplodingHead",
    title: i18n.t("landingFeatures.tenHoursUploads"),
    subtitle: i18n.t("landingFeatures.tenHoursUploadsSubtitle"),
  },
  {
    icon: "emojiTranscript",
    title: i18n.t("landingFeatures.transcriptsYourWay"),
    subtitle: i18n.t("landingFeatures.transcriptsYourWaySubtitle"),
  },
  {
    icon: "emojiFire",
    title: i18n.t("landingFeatures.lightningSpeed"),
    subtitle: i18n.t("landingFeatures.lightningSpeedSubtitle"),
  },
  {
    icon: "emojiMedia",
    title: i18n.t("landingFeatures.audioAndVideoSupport"),
    subtitle: i18n.t("landingFeatures.audioAndVideoSupportSubtitle"),
  },
  {
    icon: "emojiLock",
    title: i18n.t("landingFeatures.privacyGuaranteed"),
    subtitle: i18n.t("landingFeatures.privacyGuaranteedSubtitle"),
  },
];

const Feature = ({
  icon,
  title,
  subtitle,
}: {
  icon: keyof typeof icons;
  title: string;
  subtitle: string;
}) => {
  const { googleSignIn } = useAuth();
  const isMobile = useUISlice((state) => state.isMobile);

  return (
    <Pressable
      onPress={googleSignIn}
      style={{
        ...styles.featureContainer,
        width: isMobile ? "90%" : "30%",
      }}
    >
      <Icon icon={icon} style={styles.featureIcon} />
      <Text style={styles.featureTitle}>{title}</Text>
      <Text style={styles.featureSubtitle}>{subtitle}</Text>
    </Pressable>
  );
};

const LandingFeatures = () => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { t } = useTranslation();

  return (
    <View
      style={{
        ...styles.container,
        paddingVertical: isMobile ? 50 : 100,
      }}
    >
      <LandingPageTitle title={t("landingFeatures.title")} />

      <View style={styles.featuresGrid}>
        {features.map((feature, index) => (
          <Feature key={index} {...feature} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: DESKTOP_WIDTH,
    width: "100%",
    alignSelf: "center",
    paddingVertical: 100,
  },
  mainTitle: {
    fontSize: 48,
    fontWeight: "700",
    color: Colors.heading,
    textAlign: "center",
    marginBottom: 48,
  },
  featuresGrid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  featureContainer: {
    width: "30%",
    marginBottom: 24,
    backgroundColor: `${Colors.black}05`,
    borderRadius: 8,
    padding: 32,
  },
  featureHeader: {
    flexDirection: "row",
    alignItems: "center",
  },
  featureIcon: {
    alignSelf: "center",
    width: 48,
    height: 48,
  },
  featureTitle: {
    fontSize: 24,
    marginTop: 12,
    fontWeight: "500",
    color: Colors.title,
    textAlign: "center",
  },
  featureSubtitle: {
    marginTop: 8,
    fontSize: 16,
    lineHeight: 24,
    color: Colors.subtitle,
    textAlign: "center",
  },
});

export default LandingFeatures;
