import React from "react";
import { Text, View } from "@/ui/index";
import { Colors } from "@/constants";
import TextLink from "@/ui/TextLink";
import { useUISlice } from "@/models/useUISlice";
import { useTranslation } from "react-i18next";

export const PRIVACY_POLICY =
  "https://docs.google.com/document/d/15MEOoWj48-liTmInfCMFlHNOhBFfJK9KDWXVgrypONI/pub";
export const TERMS_OF_USE =
  "https://docs.google.com/document/d/e/2PACX-1vSzb25FpK37_YPxre-kLy0hRs0rMElSx-uFj4v59C68_FMNpQdTtULJuxac0eSZPC433uVzFyWxYPE3/pub";
export const REFUND_POLICY =
  "https://docs.google.com/document/d/e/2PACX-1vQtfO7oo9LhI_XbdrTqOzzD_wNeYjLN5tlWqqjz9rVGpGT4aOK-GP7Bm8hHDojWhIzVgEKs69K80QlH/pub";

const LandingPageFooter = () => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { t } = useTranslation();

  const _openLink = (link: string) => {
    if (typeof window !== "object") return;
    window.open(link, "_blank");
  };

  return (
    <View
      style={{
        alignSelf: "center",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
        height: isMobile ? "auto" : 48,
        width: "100%",
        backgroundColor: Colors.gray2,
        paddingVertical: isMobile ? 16 : 0,
      }}
    >
      <Text style={{ fontSize: 12 }}>{t("footer.copyright")}</Text>

      <View style={{ width: 16 }} />
      <TextLink
        fontSize={12}
        onPress={() => _openLink(REFUND_POLICY)}
        text={t("footer.refundPolicy")}
      />

      <View style={{ width: 16 }} />
      <TextLink
        fontSize={12}
        onPress={() =>
          _openLink(
            `mailto:sadeck@1transcribe.com?subject=${encodeURI("Inquiry about 1Transcribe")}`
          )
        }
        text={t("footer.contactUs")}
      />

      <View style={{ width: 16 }} />
      <TextLink
        fontSize={12}
        onPress={() => _openLink(TERMS_OF_USE)}
        text={t("footer.termsOfUse")}
      />

      <View style={{ width: 16 }} />
      <TextLink
        fontSize={12}
        onPress={() => _openLink(PRIVACY_POLICY)}
        text={t("footer.privacyPolicy")}
      />
    </View>
  );
};

export default LandingPageFooter;
