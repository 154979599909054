import { StyleSheet, View, Text } from "@/ui";
import { Colors, DESKTOP_WIDTH } from "../constants";
import ReviewItem from "./ReviewItem";
import LandingPageTitle from "./LandingPageTitle";
import { useUISlice } from "@/models/useUISlice";
import { useTranslation } from "react-i18next";

const LandingPageReviews = () => {
  const { t } = useTranslation();

  const reviews = [
    {
      review: t("landingPageReviews.review1"),
      author: "Emma Thompson",
      flag: "🇬🇧",
    },
    {
      review: t("landingPageReviews.review2"),
      author: "Carlos Mendoza",
      flag: "🇲🇽",
    },
    {
      review: t("landingPageReviews.review3"),
      author: "Dr. Yuki Tanaka",
      flag: "🇯🇵",
    },
    {
      review: t("landingPageReviews.review4"),
      author: "Sophie Dubois",
      flag: "🇫🇷",
    },
    {
      review: t("landingPageReviews.review5"),
      author: "Raj Patel",
      flag: "🇮🇳",
    },
    {
      review: t("landingPageReviews.review6"),
      author: "Li Wei",
      flag: "🇨🇳",
    },
    {
      review: t("landingPageReviews.review7"),
      author: "Prof. Hans Schmidt",
      flag: "🇩🇪",
    },
    {
      review: t("landingPageReviews.review8"),
      author: "Maria Silva",
      flag: "🇧🇷",
    },
    {
      review: t("landingPageReviews.review9"),
      author: "John Anderson",
      flag: "🇺🇸",
    },
    {
      review: t("landingPageReviews.review10"),
      author: "Sarah Johnson",
      flag: "🇺🇸",
    },
    {
      review: t("landingPageReviews.review11"),
      author: "Michael Thompson",
      flag: "🇨🇦",
    },
    {
      review: t("landingPageReviews.review12"),
      author: "Giulia Rossi",
      flag: "🇮🇹",
    },
  ];

  const isMobile = useUISlice((state) => state.isMobile);

  return (
    <View
      style={{
        flexGrow: 1,
        paddingVertical: isMobile ? 50 : 100,
        width: "100%",
        maxWidth: DESKTOP_WIDTH,
        alignSelf: "center",
      }}
    >
      <LandingPageTitle title={t("landingPageReviews.title")} />
      <View
        style={{
          flexDirection: "row",
          gap: 16,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {reviews.map((review) => (
          <ReviewItem
            key={review.author}
            review={review.review}
            author={review.author}
            flag={review.flag}
          />
        ))}
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  title: {
    color: Colors.title,
    alignSelf: "center",
    fontWeight: "700",
    fontSize: 48,
    lineHeight: 56,
    textAlign: "center",
  },
});

export default LandingPageReviews;
